/**
 * Stimulus controller to handle drag-and-drop sorting using Shopify's Draggable library.
 *
 * This controller enables users to rearrange a list of items and updates the backend with the new order.
 * It listens for the `drag:stopped` event and sends an AJAX request to update the order.
 */
import { Controller } from "@hotwired/stimulus"
import { Sortable, Plugins, Sensors } from "@shopify/draggable"

export default class extends Controller {
  /**
   * Stimulus values.
   * @property {String} urlValue - The API endpoint to update the order.
   */
  static values = {
    url: String,
    draggableClass: { type: String, default: "sortable-list__list-item" },
    handleClass: { type: String, default: "sortable-list__drag-handle" },
  }

  static targets = ["item"]

  connect() {
    this.sortable = new Sortable(this.element, {
      draggable: `.${this.draggableClassValue}`,
      handle: `.${this.handleClassValue}`,
      swapAnimation: {
        duration: 100,
        easingFunction: "ease-in-out",
        horizontal: false,
      },
      plugins: [Plugins.SwapAnimation],
      mirror: {
        constrainDimensions: true,
      },
      /*
        We disable the `MouseSensor` and replace it with `DragSensor`
        because of a bug we discovered that happens exclusively on desktop
        browsers (which use `MouseSensor`) and when the sortable list is
        inside a fixed dialog.
        Mobile browsers use the `TouchSensor` by default so this change
        won't affect them.

        For more info, see:
        https://www.notion.so/Editor-v1-Drag-sort-offset-bug-on-desktop-browsers-1c4ab5cbbeca8078bee0f779921b4e13
      */
      sensors: [Sensors.DragSensor],
      exclude: {
        sensors: [Sensors.MouseSensor]
      }
    })

    // Listen for when sorting is completed
    this.sortable.on("drag:stopped", () => {
      const tokens = this.getTokensInOrder() // Get tokens in the current order

      this.updateOrder(tokens)
    })
  }

  /**
   * Tracks movement when an item is sorted.
   *
   */
  getTokensInOrder() {
    const items = this.itemTargets
    return Array.from(items).map((item) => item.dataset.token)
  }

  /**
   * Sends an AJAX request to update the order on the server.
   *
   * @param {String} token1 - The token of the dragged item.
   * @param {String} token2 - The token of the item it was dragged over.
   */
  updateOrder(tokens) {
    // Ensure tokens is a non-empty array before making an API request
    if (!tokens || !Array.isArray(tokens) || tokens.length === 0) {
      return
    }

    /**
     * Payload structure:
     * Frontend sends:
     * {
     *   tokens: [token1, token2]
     * }
     *
     * Rails receives:
     * params[:tokens] # => ["token1", "token2"]
     */
    const payload = {
      tokens: tokens,
    }

    // Use `beforeSend` to ensure the request sends JSON data with the correct `Content-Type` header.
    // By default, `Rails.ajax` does not stringify the payload or set the `Content-Type` header for JSON requests.
    // Reference: https://gist.github.com/kohheepeace/c551a4d97e0cf7748465a8321c1bd7dd#gistcomment-5038253

    Rails.ajax({
      url: this.urlValue,
      type: "PATCH",
      data: payload,
      dataType: "json",
      beforeSend: (xhr, options) => {
        if (options.dataType === "json") {
          xhr.setRequestHeader("Content-Type", "application/json")
          options.data = JSON.stringify(options.data)
        }
        return true
      },
    })
  }
}
