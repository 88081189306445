const getBreakpoint = (breakpoint, rangeType) => {
  return parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      `--break-point-${breakpoint}-${rangeType}`,
    ),
  )
}

const breakpoints = {
  tiny: {
    start: getBreakpoint("tiny", "start"),
    end: getBreakpoint("tiny", "end"),
  },
  small: {
    start: getBreakpoint("small", "start"),
    end: getBreakpoint("small", "end"),
  },
  medium: {
    start: getBreakpoint("medium", "start"),
    end: getBreakpoint("medium", "end"),
  },
  large: {
    start: getBreakpoint("large", "start"),
    end: getBreakpoint("large", "end"),
  },
  huge: {
    start: getBreakpoint("huge", "start"),
    end: getBreakpoint("huge", "end"),
  },
}

export default breakpoints
