export default {
  snakeToKebab(string) {
    if (!string) return string
    return string.replace(/_/g, "-")
  },

  kebabToSnake(string) {
    if (!string) return string
    return string.replace(/-/g, "_")
  },

  snakeToCamel(string) {
    if (!string) return string
    return string.replace(/_([a-z])/g, (_, match) => match.toUpperCase())
  },

  camelToSnake(string) {
    if (!string) return string
    return string.replace(/([a-z0-9])([A-Z])/g, "$1_$2").toLowerCase()
  },

  kebabToCamel(string) {
    if (!string) return string
    return string.replace(/-([a-z])/g, (_, match) => match.toUpperCase())
  },

  camelToKebab(string) {
    if (!string) return string
    return string.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase()
  }
}