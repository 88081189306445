// It allows easy detection of breakpoint changes based on window media queries
class BreakpointChange {

  // builder class method to create a new instance of BreakpointChange
  // with the default breakpoints from the global.helpers.breakpoints object
  static buildFromGlobal() {
    return new BreakpointChange([
      { name: "tiny", query: BreakpointChange.queryMatchFor(global.helpers.breakpoints.tiny) },
      { name: "small", query: BreakpointChange.queryMatchFor(global.helpers.breakpoints.small) },
      { name: "medium", query: BreakpointChange.queryMatchFor(global.helpers.breakpoints.medium) },
      { name: "large", query: BreakpointChange.queryMatchFor(global.helpers.breakpoints.large) },
      { name: "huge", query: BreakpointChange.queryMatchFor(global.helpers.breakpoints.huge) },
    ])
  }

  constructor(breakpoints, options = {}) {
    this.breakpoints = breakpoints
    this.listeners = {}
    this._triggerOnAddition = options.triggerOnAddition || false

    this.breakpoints.forEach(breakpoint => {
      const mediaQueryList = window.matchMedia(breakpoint.query)
      mediaQueryList.addEventListener("change", (event) => {
        this._handleBreakpointChange(breakpoint.name, event)
      })
    })
  }

  onAll(callback, options = {}) {
    this.breakpoints.forEach(breakpoint => {
      this._registerListener(breakpoint.name, callback)
    })

    this._initialTrigger(options)
  }

  on(incomingBreakingNames, callback, options = {}) {
    const breakpointNames = Array.isArray(incomingBreakingNames) ? incomingBreakingNames : [incomingBreakingNames]
    breakpointNames.forEach(breakpointName => {
      this._registerListener(breakpointName, callback)
    })

    this._initialTrigger(options)
  }

  set triggerOnAddition(value) {
    this._triggerOnAddition = value
  }

  _initialTrigger(options) {
    if (options.triggerOnAddition || this._triggerOnAddition) {
      const currentBreakpoint = this.breakpoints.find(breakpoint => window.matchMedia(breakpoint.query).matches)
      if (currentBreakpoint) {
        this._handleBreakpointChange(currentBreakpoint.name, { matches: true })
      }
    }
  }

  _handleBreakpointChange(breakpointName, event) {
    if (this.listeners[breakpointName]) {
      this.listeners[breakpointName].forEach(callback => {
        callback(event.matches)
      })
    }
  }


  _registerListener(breakpointName, callback) {
    if (!this.listeners[breakpointName]) {
      this.listeners[breakpointName] = []
    }
    this.listeners[breakpointName].push(callback)
  }


  static queryMatchFor(breakpoint) {
    const min = `(min-width: ${breakpoint.start}px)`
    const max = `(max-width: ${breakpoint.end}px)`

    return `${min} and ${max}`
  }
}

export default BreakpointChange

