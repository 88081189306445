const loaderHelper = {
  show: () => {
    const loadingIndicator = document.querySelector("#loading-indicator")
    if (loadingIndicator) loadingIndicator.style.display = "block"
  },

  hide: () => {
    const loadingIndicator = document.querySelector("#loading-indicator")
    if (loadingIndicator) loadingIndicator.style.display = "none"
  }
}

export default loaderHelper