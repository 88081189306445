import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "editableHeader",
    "headerInput",
    "editableSubHeader",
    "subHeaderInput",
  ]

  connect() {
    this.updateContentEditable()
    this.storeInitialValues()
  }

  updateContentEditable() {
    this.editableHeaderTarget.setAttribute("contenteditable", true)
    this.editableSubHeaderTarget.setAttribute("contenteditable", true)
  }

  updateHiddenFields() {
    this.headerInputTarget.value = this.editableHeaderTarget.innerHTML
    this.subHeaderInputTarget.value = this.editableSubHeaderTarget.innerHTML
  }

  handleKeyBindings(event) {
    if (event.metaKey || event.ctrlKey) {
      switch (event.key.toLowerCase()) {
        case "b":
          event.preventDefault()
          this.formatText("bold")
          break
        case "i":
          event.preventDefault()
          this.formatText("italic")
          break
        case "u":
          event.preventDefault()
          this.formatText("underline")
          break
      }
    }
  }

  formatText(command) {
    document.execCommand(command, false, null)
  }

  storeInitialValues() {
    this.initialHeaderValue = this.editableHeaderTarget.innerHTML.trim()
    this.initialSubHeaderValue = this.editableSubHeaderTarget.innerHTML.trim()
  }

  autoSaveChanges() {
    const currentHeaderValue = this.editableHeaderTarget.innerHTML.trim()
    const currentSubHeaderValue = this.editableSubHeaderTarget.innerHTML.trim()

    if (
      currentHeaderValue !== this.initialHeaderValue ||
      currentSubHeaderValue !== this.initialSubHeaderValue
    ) {
      this.element.querySelector("form").requestSubmit()
      this.storeInitialValues()
    }
  }

  handleVisibilityChange(event) {
    if (document.hidden) {
      this.autoSaveChanges(event)
    }
  }

  handleBeforeUnload(event) {
    this.autoSaveChanges(event)
  }
}
