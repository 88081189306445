/**
 * Array helper functions
 */
export default {
  /**
   * Checks if two arrays contain the same elements (order-independent).
   * @param {Array} a - First array to compare
   * @param {Array} b - Second array to compare
   * @returns {boolean} True if arrays contain the same elements, false otherwise
   */
  equal(a, b) {
    if (!a || !b) return false
    if (a.length !== b.length) return false
    return a.every(val => b.includes(val)) && b.every(val => a.includes(val))
  }
}
